@tailwind base;
@tailwind components;
@tailwind utilities;

.scroll-none::-webkit-scrollbar{
    display: none;
}

dialog::backdrop{
    background-color: rgba(0, 0, 0, 0.562);
}